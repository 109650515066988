import { supabase } from "./supabase";

// MAIN

{
  const dom = getDomMap();
  const store = {
    origSubmitButtonLabel: dom.submitButton.textContent as string,
  };
  const onSubmit = async (event: Event) => {
    console.log("onSubmit");
    event.preventDefault();
    disableForm(dom);
    replaceSubmitButtonLabel(dom, "Loading ...");
    const email = dom.emailInput.value;
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: { shouldCreateUser: false },
    });
    if (error) {
      alert(`Cannot send a magic link for this email.\n(${error.message})`);
      replaceSubmitButtonLabel(dom, store.origSubmitButtonLabel);
      enableForm(dom);
    } else {
      showSuccess(dom);
    }
  };
  dom.loginForm.addEventListener("submit", onSubmit);
  enableForm(dom);
}

// HELPER

type DomMap = {
  loginForm: HTMLFormElement;
  emailInput: HTMLInputElement;
  submitButton: HTMLButtonElement;
  success: HTMLParagraphElement;
  box: HTMLDivElement;
};

function getDomMap(): DomMap {
  return {
    loginForm: document.getElementById("loginForm") as HTMLFormElement,
    emailInput: document.getElementById("emailInput") as HTMLInputElement,
    submitButton: document.getElementById("submitButton") as HTMLButtonElement,
    success: document.getElementById("success") as HTMLParagraphElement,
    box: document.getElementById("box") as HTMLDivElement,
  };
}

function disableForm(dom: DomMap) {
  dom.emailInput.disabled = true;
  dom.submitButton.disabled = true;
}

function enableForm(dom: DomMap) {
  dom.emailInput.disabled = false;
  dom.submitButton.disabled = false;
}

function showSuccess(dom: DomMap) {
  dom.success.classList.remove("invisible");
  dom.success.classList.remove("opacity-0");
}

function replaceSubmitButtonLabel(dom: DomMap, label: string) {
  dom.submitButton.textContent = label;
}
